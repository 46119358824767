import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  canActivate(url: string): boolean {
    // TODO: validar se token expirado e dar um refresh token
    const tokenService = inject(TokenService);
    const allowAccess: boolean = tokenService.token && !tokenService.isExpired() ? true : false;
    if (!allowAccess) {
      const router = inject(Router);
      void router.navigate(['/signin'], {
        queryParams: { returnUrl: url ?? '/' },
      });
    }
    return allowAccess;
  }
}

export const canActivateRoute: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(state.url);
};
